// Dans le "bloc" sit à propos, on ajoute un bouton pour voir plus ou moins si le texte à dépassé une hauteur de 400px
var sitProposHeight = $('.single-sit .text');
var btnShowMorePropos = $('.single-sit .btn-view-more');

sitProposHeight.each(function () {
    if ($(this).height() >= 120) {
        $(this).parent().addClass('view-more-active');

        btnShowMorePropos.on('click', function () {
            $(this).prev().toggleClass('toggle-height');
        });
    }
});
